// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import axios from 'axios';
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import Chart from 'chart.js/auto';
global.Chart = Chart;

Rails.start()
Turbolinks.start()
ActiveStorage.start()
window.Rails = Rails;

//require("bootstrap/dist/js/bootstrap")
import "stylesheets/application.scss"
//import 'bootstrap'
//import { ToolTip } from 'bootstrap'
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

require("custom/application.js")
