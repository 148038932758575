window.log = function (msg) {
	console.log("%s %o", msg, this);
	return this;
};

window.logo =  function (msg) {
	console.log(JSON.stringify(msg));
	return this;
};

/*
document.getElementById('open-nav').addEventListener("click", e => {
	e.preventDefault();
	document.getElementById("mySidenav").style.width = "250px";
});*/

document.addEventListener("turbolinks:load", () => {
	var triggerList = [].slice.call(document.querySelectorAll('.emoji_link'))
	var linkList = triggerList.map(function (linkEl) {
		linkEl.addEventListener("click", e => {
			e.preventDefault();
			var show_link_id = linkEl.attributes['data-show-link-id'].value
			var hide_link_id = linkEl.attributes['data-hide-link-id'].value
			var emoji_form_id = linkEl.attributes['data-emoji-form-id'].value
			log(show_link_id)
			log(hide_link_id)
			log(emoji_form_id)
			document.getElementById(emoji_form_id).style.display = 'block';
			document.getElementById(show_link_id).style.display = 'none';
			document.getElementById(hide_link_id).style.display = 'inline-block';
		});
	})
	
	var triggerList = [].slice.call(document.querySelectorAll('.cancel_emoji_link'))
	var linkList = triggerList.map(function (linkEl) {
		linkEl.addEventListener("click", e => {
			e.preventDefault();
			var show_link_id = linkEl.attributes['data-show-link-id'].value
			var hide_link_id = linkEl.attributes['data-hide-link-id'].value
			var emoji_form_id = linkEl.attributes['data-emoji-form-id'].value
			document.getElementById(emoji_form_id).style.display = 'none';
			document.getElementById(show_link_id).style.display = 'inline-block';
			document.getElementById(hide_link_id).style.display = 'none';
		});
	})
	
	var triggerList = [].slice.call(document.querySelectorAll('.comment_link'))
	var linkList = triggerList.map(function (linkEl) {
		linkEl.addEventListener("click", e => {
			e.preventDefault();
			var message_id = linkEl.attributes['data-message-id'].value
			var uuid = linkEl.attributes['data-uuid'].value
			document.getElementById('message_' + message_id + '_comments_' + uuid).style.display = 'block';
			document.getElementById('message_' + message_id + '_comment_form_' + uuid).style.display = 'block';
			document.getElementById('message_' + message_id + '_comment_link_' + uuid).style.display = 'none';
			document.getElementById('message_' + message_id + '_cancel_comment_link_' + uuid).style.display = 'inline-block';
			document.querySelector('#message_' + message_id + '_comment_form_' + uuid + ' #message_comment_comment').focus();
			document.getElementById('message_' + message_id + '_comment_form_link_' + uuid).style.display = 'none';
		});
	})
	
	
	var triggerList = [].slice.call(document.querySelectorAll('.cancel_comment_link'))
	var linkList = triggerList.map(function (linkEl) {
		linkEl.addEventListener("click", e => {
			e.preventDefault();
			var message_id = linkEl.attributes['data-message-id'].value
			var uuid = linkEl.attributes['data-uuid'].value
			var comments_wrapper = document.getElementById('message_' + message_id + '_comments_' + uuid)
			if(comments_wrapper.hasAttribute('data-feed-type') && comments_wrapper.attributes['data-feed-type'].value == "activity") {
				comments_wrapper.style.display = 'none';	
			}
			
			document.getElementById('message_' + message_id + '_comment_form_' + uuid).style.display = 'none';
			document.getElementById('message_' + message_id + '_comment_form_link_' + uuid).style.display = 'block';
			document.getElementById('message_' + message_id + '_comment_link_' + uuid).style.display = 'inline-block';
			document.getElementById('message_' + message_id + '_cancel_comment_link_' + uuid).style.display = 'none';
		});
	})
})


////https://stackoverflow.com/questions/54501241/rails-5-6-how-to-include-js-functions-with-webpacker
// LOAD THE HEADER TIPS FOR STAT TABLES
document.addEventListener("turbolinks:load", () => {
	var popoverTriggerList = [].slice.call(document.querySelectorAll('.header_cell[data-bs-toggle="popover"]'))
	var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
		return new bootstrap.Popover(popoverTriggerEl, {
			delay: {
				"show": 500,
				"hide": 100
			},
			trigger: 'hover focus'
		})
	})
})

document.addEventListener("turbolinks:load", () => {
	var popoverTriggerList = [].slice.call(document.querySelectorAll('.battleshorts_shots_wrapper[data-bs-toggle="popover"]'))
	var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
		return new bootstrap.Popover(popoverTriggerEl, {
			delay: {
				"show": 500,
				"hide": 100
			},
			trigger: 'hover focus'
		})
	})
})

document.addEventListener("turbolinks:load", () => {
	var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="general_popover"]'))
	var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
		return new bootstrap.Popover(popoverTriggerEl, {
			delay: {
				"show": 500,
				"hide": 100
			},
			trigger: 'hover focus'
		})
	})
})

document.addEventListener("turbolinks:load", () => {
	var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
	var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
		return new bootstrap.Popover(popoverTriggerEl, {
			delay: {
				"show": 500,
				"hide": 100
			},
			trigger: 'hover focus'
		})
	})
})